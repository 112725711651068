import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CurrentMissionService } from 'app/services/current-job.service';
import {
  Mission,
  MqttSettings,
  Robot,
} from 'rm-api-services/dist/api-services';
import { Subscription, filter, map, tap } from 'rxjs';

@Component({
  selector: 'robot-status',
  template: `<div class="flex items-center" *ngIf="robot?.status !== 2">
      <ng-container *ngIf="currentMission">
        <mat-icon
          class="text-success scale-50  -m-[0.4rem]"
          svgIcon="mat_solid:circle"
        ></mat-icon>
        <span class="ml-2">{{
          currentMission.name
            ? (currentMission.name
              | removeDateTime
              | removeBracket
              | robotJobStatus)
            : (currentMission.skills | robotJobStatus)
        }}</span>
      </ng-container>
      <ng-container *ngIf="!currentMission">
        <mat-icon
          class="scale-50 -m-[0.4rem]"
          [ngClass]="{
            'text-warning':
              robot?.state === 2 || robot?.state === 1 || robot?.state === 4,
            'text-success': robot?.state === 3 || robot?.state === 6,
            'text-error-300': robot?.state === 5
          }"
          svgIcon="mat_solid:circle"
        ></mat-icon>
        <span class="ml-2">{{ robot?.state | robotStatus }}</span>
      </ng-container>
    </div>
    <div class="flex items-center" *ngIf="robot?.status == 2">
      <mat-icon
        class="scale-50 -m-[0.4rem]"
        svgIcon="mat_solid:circle"
      ></mat-icon>
      <span class="ml-2">Offline</span>
    </div>`,
})
export class RobotStatusComponent implements OnInit, OnDestroy {
  @Input() robot: Robot;

  public currentMission: Mission;
  private sub: Subscription;

  constructor(
    private currentMissionService: CurrentMissionService,
    private mqttSettings: MqttSettings
  ) {}

  ngOnInit(): void {
    if (!this.robot) {
      return;
    }

    // Initialize a variable to track the previous state of the robot
    let prevRobotState = null;

    // Subscribe to the socketRbtStateData$ stream
    this.sub = this.mqttSettings.socketRbtStateData$
      .pipe(
        filter((robot) => robot.robotId === this.robot.id),
        tap((robot) => {
          // Check if the robot state has changed
          if (robot.state !== prevRobotState) {
            console.log('====================================');
            console.log('Robot state has changed', robot.state);
            console.log('====================================');
            // Update the previous state variable
            prevRobotState = robot.state;
            // Update the robot state
            this.robot.state = robot.state;
            // Retrieve the current mission associated with the robot
            this.currentMissionService
              .getRobotSubject(this.robot.id)
              .pipe(
                map((missions) =>
                  missions.find((mission) => mission.status === 5)
                )
              )
              .subscribe((mission) => (this.currentMission = mission));
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }
}
